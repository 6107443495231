import React from "react";
import { addMonths, differenceInDays, format, parseISO } from "date-fns";
import useClient from "hooks/useClient";
import { usePayment } from "hooks/usePayment";
import { FaExclamationTriangle } from "react-icons/fa";
import { LiaCoinsSolid, LiaKeySolid } from "react-icons/lia";
import QRCode from "qrcode.react";

import userStore from "store/userStore";
import { useHttp } from "hooks/useHttp";
import Spacer from "components/Spacer";

const Dashboard = () => {
  const { clientId, accountType, credits } = userStore();
  const { getSiteConfiguration, getEnterprisePaymentRecord, downgradeToPersonal } = useClient();
  const { handlePayment, handleEnterprisePayment } = usePayment();

  const { protectedHttp } = useHttp();
  const [loading, setLoading] = React.useState(true);
  const { whatsappLoggedIn } = userStore();
  const [qrCode, setQrCode] = React.useState("");

  const [buyingCredits, setBuyingCredits] = React.useState(1000); // Set initial value to minimum
  const [lkrAmount, setLkrAmount] = React.useState((1000 * 0.8).toFixed(2));

  const [notice, setNotice] = React.useState("");
  const [enterprisePaymentRecord, setEnterprisePaymentRecord] = React.useState(null);
  const [showRenewButton, setShowRenewButton] = React.useState(false);
  const [planExpired, setPlanExpired] = React.useState(false);

  const getQrCode = async () => {
    setLoading(true);
    try {
      const { data } = await protectedHttp.get("/client/get-qr");
      setQrCode(data.qr);
    } catch (error) {
      console.error("Get QR code error:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getSiteConfiguration().then((config) => setNotice(config.clientNotice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreditChange = (e) => {
    const creditValue = e.target.value;
    setBuyingCredits(creditValue);

    const lkrValue = (creditValue * 0.8).toFixed(2);
    setLkrAmount(lkrValue);
  };

  const handleAddCredits = async () => {
    if (buyingCredits < 1000) {
      alert("The minimum credit amount is 1000 credits.");
      return;
    }

    await handlePayment(buyingCredits, lkrAmount);
  };

  const handleGetEnterprisePaymentRecord = async () => {
    const { payment } = await getEnterprisePaymentRecord();
    if (payment) {
      setEnterprisePaymentRecord(payment);
    } else {
      if (credits === 0) {
        setPlanExpired(true);
      }
    }
  };

  const enterprisePlanDateRange = () => {
    const currentDate = new Date(enterprisePaymentRecord.createdAt);

    const startDate = format(currentDate, "MMM d");

    const endDate = format(addMonths(currentDate, 1), "MMM d");

    const dateRange = `${startDate} - ${endDate}`;

    return dateRange;
  };

  const calculateDateRangeAndCheckRenewal = () => {
    const currentStartDate = parseISO(enterprisePaymentRecord.createdAt);

    const endDate = addMonths(currentStartDate, 1);
    const currentDate = new Date();

    const daysUntilExpiration = differenceInDays(endDate, currentDate);

    if (daysUntilExpiration <= 5 && daysUntilExpiration >= 0) {
      setShowRenewButton(true);
    } else {
      setShowRenewButton(false);
    }
  };

  React.useEffect(() => {
    accountType === "enterprise" && handleGetEnterprisePaymentRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType, credits]);

  React.useEffect(() => {
    enterprisePaymentRecord && calculateDateRangeAndCheckRenewal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterprisePaymentRecord]);

  React.useEffect(() => {
    const shouldGetQrCode =
      (accountType === "enterprise" && enterprisePaymentRecord) ||
      (accountType === "personal" && credits > 0) ||
      (accountType === "demo" && credits > 0);

    if (shouldGetQrCode && !whatsappLoggedIn) {
      getQrCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatsappLoggedIn, enterprisePaymentRecord, credits, accountType]);

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Add Credits
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="creditAmount" className="form-label">
                    Enter Credit Amount (1 credit = 0.80 LKR)
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="creditAmount"
                    value={buyingCredits}
                    onChange={handleCreditChange}
                    min="1000"
                    placeholder="Minimum 1000 credits"
                    required
                  />
                  <div className="form-text">Minimum credit value: 1000 credits (800 LKR)</div>
                </div>
                <div className="mb-3">
                  <label htmlFor="lkrAmount" className="form-label">
                    LKR Amount
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lkrAmount"
                    value={`${lkrAmount} LKR`}
                    readOnly
                    disabled
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-success" onClick={handleAddCredits}>
                Add Credits
              </button>
            </div>
          </div>
        </div>
      </div>

      {notice && (
        <div className="container d-flex align-items-center">
          <div className="col col-md col-lg-8 mx-auto mt-4">
            <div className="alert alert-warning d-flex align-items-center" role="alert">
              <FaExclamationTriangle className="me-2" size={20} />
              <div>{notice}</div>
            </div>
          </div>
        </div>
      )}

      <div className="container d-flex align-items-center">
        <div className="col col-md col-lg-4 mx-auto mt-4">
          <div className="card shadow radius-10 border-start border-0 border-3 border-success">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <p className="mb-0 text-secondary">Remaining Credits</p>
                  <h4 className="my-1 text-success">
                    {accountType === "enterprise" ? "Unlimited" : credits}
                  </h4>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center rounded-circle bg-success"
                  style={styles.iconWrapper}
                >
                  <LiaCoinsSolid size={30} color="#fff" />
                </div>
              </div>

              {accountType === "enterprise" && (
                <>
                  <p className="mb-0 text-secondary">
                    {planExpired && <span className="text-danger">Plan has been expired </span>}

                    {enterprisePaymentRecord !== null && !planExpired && enterprisePlanDateRange()}

                    {showRenewButton && (
                      <a
                        onClick={async (e) => {
                          e.preventDefault();
                          await handleEnterprisePayment();
                        }}
                        href="/"
                        className="link-success ms-2"
                      >
                        Renew now
                      </a>
                    )}

                    {planExpired && (
                      <a
                        onClick={async (e) => {
                          e.preventDefault();
                          await handleEnterprisePayment();
                        }}
                        href="/"
                        className="link-success ms-2"
                      >
                        Renew now
                      </a>
                    )}

                    {planExpired && (
                      <div className="d-flex gap-4 mt-4">
                        <a
                          onClick={async (e) => {
                            e.preventDefault();
                            await downgradeToPersonal();
                          }}
                          href="/"
                          className="link-primary"
                        >
                          Downgrade to personal plan
                        </a>
                      </div>
                    )}
                  </p>
                </>
              )}

              {accountType === "personal" && (
                <>
                  <div className="d-flex gap-4 mt-4">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      href="/"
                      className="link-success"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Add Credits
                    </a>
                  </div>

                  <div className="d-flex gap-4 mt-4">
                    <a
                      onClick={async (e) => {
                        e.preventDefault();
                        await handleEnterprisePayment();
                      }}
                      href="/"
                      className="link-success"
                    >
                      Upgrade to unlimited plan
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {(accountType === "enterprise" && enterprisePaymentRecord) ||
      (accountType === "personal" && credits > 0) ? (
        <div className="container d-flex align-items-center mb-4">
          <div className="col col-md col-lg-4 mx-auto mt-4">
            <div className="card shadow radius-10 border-start border-0 border-3 border-primary">
              <div className="card-body">
                {whatsappLoggedIn ? (
                  // API Key Section
                  <>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="text-truncate" style={{ width: 300 }}>
                        <p className="mb-0 text-secondary">API Key</p>
                        <h4
                          className="my-1 text-primary text-truncate"
                          style={{ maxWidth: "100%" }}
                        >
                          {clientId}
                        </h4>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center rounded-circle bg-primary"
                        style={styles.iconWrapper}
                      >
                        <LiaKeySolid size={30} color="#fff" />
                      </div>
                    </div>

                    <div className="d-flex gap-4 mt-4">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          navigator.clipboard.writeText(clientId);
                        }}
                        href="/"
                        className="link-primary"
                      >
                        Copy to clipboard
                      </a>
                    </div>
                  </>
                ) : (
                  // QR Code Section
                  <>
                    <h5 className="card-title">Scan the below QR code</h5>
                    <p className="mb-0 text-secondary mb-2">
                      Open WhatsApp on your phone and scan the QR code to get started.
                    </p>
                    <div
                      className="card d-flex justify-content-center align-items-center p-2"
                      style={styles.container}
                    >
                      {loading ? (
                        <>
                          <div className="spinner-border" role="status"></div>
                          <Spacer height={40} />
                          <small className="text-muted text-center">Please wait...</small>
                          <small className="text-muted text-center">
                            This may take up to five minutes, sometimes.
                          </small>
                        </>
                      ) : (
                        <>
                          <QRCode value={qrCode} size={320} />
                          <small className="text-muted text-center mt-2">
                            Don't try to link a previously linked WhatsApp account to WhatsFlow, it
                            will immidiately ban your account.
                          </small>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {accountType === "demo" && whatsappLoggedIn && (
        <>
          <div className="container d-flex align-items-center mb-4">
            <div className="col col-md col-lg-4 mx-auto mt-4">
              <div className="card shadow radius-10 border-start border-0 border-3 border-primary">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-truncate" style={{ width: 300 }}>
                      <p className="mb-0 text-secondary">API Key</p>
                      <h4 className="my-1 text-primary text-truncate" style={{ maxWidth: "100%" }}>
                        {clientId}
                      </h4>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center rounded-circle bg-primary"
                      style={styles.iconWrapper}
                    >
                      <LiaKeySolid size={30} color="#fff" />
                    </div>
                  </div>

                  <div className="d-flex gap-4 mt-4">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(clientId);
                      }}
                      href="/"
                      className="link-primary"
                    >
                      Copy to clipboard
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;

const styles = {
  iconWrapper: {
    width: 60,
    height: 60,
  },
};
